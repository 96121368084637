import React from 'react';
// import Detector from './pages/Detector';
import Gradio from './pages/Gradio';
// import Home from './pages/Home';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; 
// import Login from "./pages/Login";
// import Register from "./pages/Signup";
// import Reset from "./pages/Reset";
// import Dashboard from "./pages/Dashboard";

function App() {
  return (
    <div className="App">
    <Router>
      <Routes>
        <Route exact path="/" element={<Gradio />} />
        {/* <Route exact path="/register" element={<Register />} />
        <Route exact path="/reset" element={<Reset />} />
        <Route exact path="/dashboard" element={<Dashboard />} /> */}
      </Routes>
    </Router>
  </div>
  );
}

export default App;