// import React from 'react'
// import { client } from '@gradio/client'

// async function Gradio() {


// const response_0 = await fetch("https://raw.githubusercontent.com/gradio-app/gradio/main/test/test_files/bus.png");
// const exampleImage = await response_0.blob();
						
// const app = await client("https://kimata-multimodal-deepfake-detection.hf.space/");
// const result = await app.predict("/predict", [
// 				exampleImage, 	// blob in 'input_image' Image component
// 	]);

// console.log(result.data);


//   return (
//     <div>
//         <h1>result.data</h1>
//     </div>
//   )
// }

// export default Gradio
import React from 'react';

function Gradio() {
  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <iframe
        src="https://vivek-metaphy-metaphy-antiai.hf.space/"
        title='deepReal ai'
        frameBorder="0"
        width="100%"
        height="100%"
      ></iframe>
    </div>
  );
}

export default Gradio;